@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.action-container {
  display: flex;
  margin-bottom: 20px;
}

::v-deep table th {
  &.col-name {
    width: 20%;
  }
  &.col-actions {
    width: 15%;
  }
}
